import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getMerchantApiKey } from '../../redux/actions/merchants';
import Credentials from '../common/Credentials';
import './ApiCredentials.css';
import { PRODUCTIVE_MODE, SANDBOX_MODE } from '../../common/authorizationModeType';
function ApiCredentialsComponent() {
  const dispatch = useDispatch();
  const prodCredentials = useSelector(state => {
    return state?.session?.apiKey;
  });
  const testCredentials = useSelector(state => {
    return state?.session?.apiKeySandBox;
  });

  useEffect(() => {
    dispatch(getMerchantApiKey(PRODUCTIVE_MODE));
    dispatch(getMerchantApiKey(SANDBOX_MODE));
  }, [dispatch]);

  return (
    <div className="wrap-api-credentials">
      <Credentials
        credentials={{
          prodCredentials: prodCredentials,
          testCredentials: testCredentials,
        }}
      />
    </div>
  );
}

ApiCredentialsComponent.defaultProps = {
  getMerchantApiKey: null,
  classes: null,
  merchantInfo: null,
  apiKey: null,
};

ApiCredentialsComponent.propTypes = {
  getMerchantApiKey: PropTypes.func,
  classes: PropTypes.object,
  merchantInfo: PropTypes.object,
  apiKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ApiCredentialsComponent;
